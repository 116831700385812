header {
    height: 70px;
    width: 100%;
    background-color: rgba(13, 25, 43, 0.4);
    color: #ffff;
    position: fixed;
    z-index: 10;   
}

.logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.logo > img {
    display: block;
    width: 70px;
    height: 100%;
}

.contato {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}



@media (min-width: 1200px) {

    .header-container {
        max-width: 1320px;
        height: 70px;
        display: grid;
        grid-template-columns: 25% auto 25%;
        margin: 0 auto;
    }

    .logo, .nav, .contato {
        width: 100%;
        height: 100%;
    }

    .modal-toggle {
        display: none;
    }

    .nav {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .nav > a{
        font-weight: 400;
        margin: 0 15px;
        justify-content: space-between;
        text-decoration: none;
        color: #ffff;
    }
    
    .nav > a:hover {
        color: #00E1FF;
        cursor: pointer;
        transition: .3s;
    }

}

/* Mobile Settings */

@media (max-width: 1199px) {

    .responsive-nav {
        visibility: hidden;
    }

    .header-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
    }

    .responsive-nav > a:active {
        background-color: transparent;
    }
    
    .nav {
        width: 90%;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        background-color: #ffff;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        padding: 20px;
    }

    .nav > a {
        color: black;
        text-decoration: none;
        padding: 10px 20px;
    }

    .modal-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}