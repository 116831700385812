.wpp-button {
    width: 60px;
    height: 60px;
    background-color: #25d366;
    color: rgba(13, 25, 43);
    position: fixed;
    bottom: 20px;
    left: 20px;
    border-radius: 50%;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    animation: vibration 2s infinite;
    animation-play-state: running;
}

@keyframes vibration {
    0% {transform: translateX(-3px);}
    5% {transform: translateX(3px);}
    10% {transform: translateX(-3px);}
    25% {transform: translateX(3px);}
    50% {transform: translateX(-3px);}
    100% {transform: translateX(3px);}
  }

