.home {
    width: 100%;
    height: 630px;
    background: url("../../assets/background-home.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin: 0 auto;
}

.home > h1 {
    color: #ffff;
    font-size: 5vw;
    font-weight: bold;
    margin: 0;
}

.home > h2 {
    color: #ffff;
    font-size: 2.2vw;
    font-weight: bold;
    margin: 0;
}

span {
    color: #00E1FF;
    font-size: 5vw;
    font-weight: bold;
    margin: 0;
}

@media (max-width: 1199px) {

    .home {
        width: 100%;
        height: 520px;
        background: url("../../assets/background-home.webp");
        background-size: cover;
        background-position: top right;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .home > h1 {
        color: #ffff;
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }
    
    .home > h2 {
        color: #ffff;
        font-size: 12px;
        font-weight: bold;
        margin: 0;
    }
    
    span {
        color: #00E1FF;
        font-size: 20px;
        font-weight: bold;
        margin: 0;
    }
    
}