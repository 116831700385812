.footer {
    width: 100%;
    padding: 60px;
    padding-bottom: 10px;
    background-color:rgba(13, 25, 43);
}

.footer-container {
    width: 1320px;
    height: 100%;
    margin: 0 auto;
}

.footer-info {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 15px;
}

.icon-button {
    height: 40px;
    width: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.footer-text {
    height: 100%;
    max-width: 200px;
    color: #ffff;
    padding-left: 15px;
    background-image: url("../../assets/rede-conhecimento.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 30px;
}

.footer-text > h1 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
}

.footer-text > p {
    font-style: italic;
    font-size: 12px;
    margin: 0;
}

.footer-top {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid #00E1FF;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    color: #ffff;
    font-size: 10px;
    padding: 0 15px;
}

.rayed-technology {
    color: #ccff00;
    font-size: 10px;
    font-weight: bold;
}

@media (max-width: 1199px) {

    .footer {
        width: 100%;
        padding: 40px;
        padding-bottom: 10px;
    }

    .footer-container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .footer-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-bottom {
        display: flex;
        justify-content: space-between;
        color: #ffff;
        font-size: 8px;
        padding: 0 15px;
    }

    .rayed-technology {
        color: #ccff00;
        font-size: 8px;
        font-weight: bold;
    }

    .footer-text {
        height: 100%;
        width: 100%;
        color: #ffff;
        padding-left: 15px;
        background-image: url("../../assets/rede-conhecimento.svg");
        background-repeat: no-repeat;
        padding: 30px;
    }

    .footer-info {
        display: flex;
        gap: 15px;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .icon-button {
        height: 40px;
        width: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}