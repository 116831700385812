@import url('https://fonts.googleapis.com/css?family=Open+Sans'); 

html, body {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    border: 0;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    line-height: 1.5;
}
