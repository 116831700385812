@media (min-width: 1200px) {

    .sobre-nos {
        display: flex;
        height: 600px;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 60px 0 30px 0;
        margin: 0 auto;
        max-width: 1320px;
    }
    
    .texto-sobre {
        display: flex;
        height: 500px;
        width: 500px;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

    }

    .texto-sobre > div {
        max-width: 500px;
    }

    .texto-sobre > div > h1 {
        font-weight: 200px;
        font-size: 28px;
        color: rgba(13, 25, 43, 0.9);
        margin: 0 5px 20px 20px;
        line-height: 1.5;
    }

    .texto-sobre p {
        margin: 0;
        font-size: 18px;
        margin: 0 5px 20px 20px;
        line-height: 1.5;
        color: #2b5b73;
    }

    .rede-de-conhecimento {
        font-size: 28px;
        margin-left: 7px;
        color: #a0ced5;
        line-height: 1.5;
    }

    .proposito {
        font-size: 18px;
        color: #a0ced5;
        line-height: 1.5;
    }

    .experiencia {
        font-size: 18px;
        color: #a0ced5;
        line-height: 1.5;
    }

}

@media (max-width: 1199px) {

    .sobre-nos {
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 30px 0;
    }

    .video-sobre {
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .texto-sobre {
        width: 80%;
        margin: 0 auto;
    }

    .texto-sobre > div > h1 {
        font-weight: 400px;
        font-size: 20px;
        color: rgba(13, 25, 43, 0.9);
        margin: 20px 15px;
        line-height: 1.5;
        text-align: start;
    }

    .texto-sobre p {
        margin: 0;
        font-size: 16px;
        margin: 20px 20px;
        line-height: 1.5;
        color: #2b5b73;
    }

    .rede-de-conhecimento {
        font-weight: 400px;
        font-size: 20px;
        margin-left: 7px;
        color: #a0ced5;
    }

    .proposito {
        font-size: 16px;
        color: #a0ced5;
        line-height: 1.5;
    }

    .experiencia {
        font-size: 16px;
        color: #a0ced5;
        line-height: 1.5;
    }
}
