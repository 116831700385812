.swiper-pagination-bullet-active {
    background-color: #00E1FF;
}

.services-image {
    width: 130%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../../assets/servicesImage.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
}

@media (min-width: 1151px) {
    .services {
        width: 100%;
    }
    
    .services-container {
        height: 520px;
        padding: 100px;
        color: #ffff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;
    }

    .services-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 60px;
    }
        
    .services-text > h1 {
        font-size: 28px;
        font-weight: bold;
        margin: 0;
    }
    .services-text > p {
        font-size: 18px;
        margin-top: 15px;
    }

    .services-li-text > h1 {
        font-size: 28px;
        font-weight: bold;
        display: flex;
        padding-left: 40px;
    }

    .services-li-text li {
        font-size: 18px;
        display: flex;
    }

    .services-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services-icon > svg {
        max-width: 300px;
        max-height: 300px;
    }

    .services-li-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services-li-icon > svg {
        max-width: 300px;
        max-height: 300px;    
    }

    .swiper-button-prev, .swiper-button-next {
        color: #ffff;
    }
    
    .services-content {
        display: flex;
        height: 400px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #ffff;
        padding: 60px 0 10px 0;
    }
      
    .image-services {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .image-services > img {
        display: block;
        width: 500px;
    }

}

@media (max-width: 599px) {
    .services {
        width: 100%;
    }
    
    .services-container {
        height: 550px;
        color: #ffff;
        padding: 60px;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .services-text {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .services-text > h1 {
        font-size: 32px;
        font-weight: bold;
        margin: 0;
        text-shadow: 1px 1px 2px #000000;
        display: flex;
        text-align: center;
    }

    .services-text > p {
        display: none;
    }

    .services-li-text > h1 {
        font-size: 32px;
        text-align: center;
    }

    .services-container > ul > li {
        padding-bottom: 8px;
        font-size: 12px;
    }

    .services-icon {
        display: flex;
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }

    .services-li-icon {
        display: none;
    }
    
}

@media (min-width: 600px) and (max-width: 1150px) {

    .services-container {
        height: 450px;
        color: #ffff;
        padding: 80px;
        text-shadow: 1px 1px 2px #000000;
    }
    
}