.divider {
    border: 1px solid #00E1FF;
    width: 120px;
    margin: 20px 0;
}

@media (max-width: 1199px) {

    .divider {
        border: 1px solid #00E1FF;
        width: 120px;
        margin: 4px 0 20px 0;
    }
}