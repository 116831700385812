
@media (min-width: 1200px) {
    
    .experience {
        width: 100%;
        padding: 60px 0;
    }

    .experience-container {
        width: 1320px;
        margin: 0 auto;
    }

    .experience-cards-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 70px;
        padding: 30px 0;
        width: 100%;
        align-items: center;
    }

    .experience-title-container > h3 {
        font-weight: bold;
        font-size: 28px;
        color: rgba(13, 25, 43, 0.9);
        margin: 0;
    }

}

@media (max-width: 1199px) {

    .experience {
        height: 100%;
        width: 100%;
        padding: 60px 0;
    }

    .experience-container {
        width: 100%;
    }

    .experience-title-container {
        padding: 0 0 20px 20px;
    }

    .experience-title-container > h3 {
        margin: 0;
        font-size: 28px;
    }

    .experience-cards-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 45px;
    }

}