@media (min-width: 1200px) {
    
    .slider-clients {
        max-width: 1320px;
        margin: 0 auto;
    }

    .slider-images {
        height: 100px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-images > img {
        width: 314px;
        height: 84px;
        display: block;
        filter: grayscale(100%);
        transition: 0.4s;
    }

    .slider-images > img:hover {
        filter: grayscale(0)
    }

}

@media (max-width: 1199px) {

    .slider-clients {
        width: 100%;
    }

    .slider-images {
        padding: 20px 0;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slider-images > img {
        display: block;
        filter: grayscale(100%);
        transition: 0.4s;
    }

    .slider-images > img:hover {
        filter: grayscale(0)
    }
}