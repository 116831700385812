.float-button {
    width: 40px;
    height: 40px;
    background-color: #00E1FF;
    color: rgba(13, 25, 43);
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 4px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.hide-float-button {
    opacity: 0;
}

@media (min-width: 1200px) {

    .float-button:hover {
        background-color: rgba(13, 25, 43);
        color: #00E1FF;
    }

}