.contact {
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-container {
    width: 1320px;
    height: 100%;
    margin: 0 auto;
    color: rgba(13, 25, 43, 0.9);
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    padding: 60px 0;
}

.contact-title-container {
    padding-bottom: 20px;
}

.contact-title-container > h3 {
    font-weight: bold;
    font-size: 28px;
    margin: 0 auto;
}

.contact-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    margin-top: 30px;
    gap: 45px;
}

.contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contact-icon {
    background-color: #00E1FF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 5px;
}

.contact-text {
    padding-left: 15px;
}

.contact-text > h1 {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
}

.contact-text > p {
    margin: 0;
    font-size: 14px;
    font-style: italic;
}

.contact-form {
    width: 100%;
    height: 100%;
}

.form-control {
    box-shadow: none;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 400;
    background-color: #fff;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    padding: 12px;
    width: 100%;
    margin-bottom: 15px;
}

.form-control:focus {
    border: 1px solid #00E1FF;
    outline: 0;
}

textarea {
    resize: vertical;
    min-height: 100px;
}

.name-email {
    display: flex;
    width: 100%;
    gap: 15px;
}

.subject-message {
    display: flex;
    flex-direction: column;
}

.map {
    width: 100%;
    height: 250px;
    border: none;
}

.send-form {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 1199px) {

    .contact {
        width: 100%;
        background-color: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
    }

    .contact-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 0;
        padding: 0;
    }

    .contact-title-container {
        padding: 0 0 20px 8px;
    }

    .contact-form, .name-email, .subject-message {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0;
    }

}