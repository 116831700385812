.card-container {
    height: 320px;
    border-radius: 5px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    display: grid;
    grid-template-rows: 260px auto;
}

.card-body {
    background-color: gray;
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 100%;
}

.card-footer {
    display: flex;
    height: 100%;
    padding-left: 20px;  
    align-items: center;
}

.card-footer > h3 {
    font-weight: bold;
    font-size: 16px;
    color: rgba(13, 25, 43, 0.9);
    margin: 0;
}

.card-body > img {
    width: 100%;
    height: 100%;
    display: block;
    filter: grayscale(100%);
    transition: 0.4s;
    border-radius: 5px 5px 0 0;
    object-fit: fill;
}

.card-body > img:hover {
    filter: grayscale(0)
}

@media (max-width: 1199px) {

    .card-container {
        width: 300px;
        height: 320px;
        border-radius: 5px;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        display: grid;
        grid-template-rows: 260px auto;
    }

    .card-body > img {
        filter: none;
    }

}