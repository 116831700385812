.comments-container {
    width: 100%;
    height: 250px;
    background-color: black;
    background-image: url("../../assets/background-home.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 30px;
}

@media (max-width: 1199px) {
    .comments-container{
        width: 100%;
        height: 150px;
    }
}