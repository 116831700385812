.button {
    padding: 8px 25px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;
}

.send-button-responsive {
    padding: 8px 25px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;
}

.outline {
    border: 2px solid #00E1FF;
    background-color: transparent;
    color: #ffff;
}

.primary {
    border: none;
    background-color: #00E1FF;
    color: rgba(13, 0, 46, .8);
}

.outline:hover {
    background-color: #00E1FF;
    color: rgba(13, 0, 46, .8);
}

@media (max-width: 1199px) {

    .button {
        padding: 3px 12px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 10px;
        transition: 0.3s;
        cursor: pointer;
        text-decoration: none;
    }
    
    .send-button-responsive {
        padding: 8px 25px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        transition: 0.3s;
        cursor: pointer;
        text-decoration: none;
    }

}